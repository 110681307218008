.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: #000 !important;
}

.react-datepicker__day--selected {
  background: #d73736 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rc-tooltip .rc-tooltip-content .rc-tooltip-inner {
  background-color: white;
  z-index: 2;
  position: relative;
  border-radius: 12px;
  box-shadow: none;
}

.rc-tooltip .rc-tooltip-content .rc-tooltip-arrow {
  z-index: 1;
  border: none;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: white;
}

.rc-tooltip .rc-tooltip-content {
  box-shadow: 0px 0px 10px 0px #69737b26;
  border-radius: 12px;
}

.rc-tooltip {
  opacity: 1 !important;
}

img {
  user-select: none;
  pointer-events: none;
}

@keyframes slide-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.animate-slide-left {
  animation: slide-left 1s forwards;
}
