@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./styles/font.scss";

body {
  font-family: "OpenSans", "sukhumvittadmai";

  @layer components {
    .table-row-th {
      @apply text-white bg-tableHeader-500;
    }
    .table-row-data {
      @apply px-2 py-1 border-2 text-sm whitespace-pre;
    }
    .table-subfooter-data {
      @apply text-black font-bold bg-tableSubFooter px-2 py-1 border-2 text-sm whitespace-pre;
    }
    .table-th {
      @apply py-2 border-2 font-normal whitespace-pre;
    }
    .table-row-footer {
      @apply py-1 border-2 font-normal whitespace-pre;
    }
    .table-cell-default {
      @apply border border-black divide-x divide-black;
    }
    .table-header-cell-default {
      @apply bg-gray-200 border border-black divide-x divide-black;
    }
  }
}
