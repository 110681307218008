@font-face {
  font-family: "sukhumvittadmai";
  src: url("../fonts/sukhumvittadmai-thin.eot");
  src: local("sukhumvittadmai-thin"),
    url("../fonts/sukhumvittadmai-thin.woff") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  size-adjust: 110%;
  unicode-range: Ux0E00- Ux0E7F;
}

@font-face {
  font-family: "sukhumvittadmai";
  src: url("../fonts/sukhumvittadmai-light.eot");
  src: local("sukhumvittadmai-light"),
    url("../fonts/sukhumvittadmai-light.woff") format("truetype");
  font-weight: 300;
  font-style: normal;
  size-adjust: 110%;
  font-display: swap;
  unicode-range: Ux0E00- Ux0E7F;
}

@font-face {
  font-family: "sukhumvittadmai";
  src: url("../fonts/sukhumvittadmai-text.eot");
  src: local("sukhumvittadmai-text"),
    url("../fonts/sukhumvittadmai-text.woff") format("truetype");
  font-weight: 400;
  font-style: normal;
  size-adjust: 110%;
  font-display: swap;
  unicode-range: Ux0E00- Ux0E7F;
}

@font-face {
  font-family: "sukhumvittadmai";
  src: url("../fonts/sukhumvittadmai-medium.eot");
  src: local("sukhumvittadmai-medium"),
    url("../fonts/sukhumvittadmai-medium.woff") format("truetype");
  font-weight: 500;
  font-style: normal;
  size-adjust: 110%;
  font-display: swap;
  unicode-range: Ux0E00- Ux0E7F;
}

@font-face {
  font-family: "sukhumvittadmai";
  src: url("../fonts/sukhumvittadmai-semibold.eot");
  src: local("sukhumvittadmai-semibold"),
    url("../fonts/sukhumvittadmai-semibold.woff") format("truetype");
  font-weight: 600;
  font-style: normal;
  size-adjust: 110%;
  font-display: swap;
  unicode-range: Ux0E00- Ux0E7F;
}

@font-face {
  font-family: "sukhumvittadmai";
  src: url("../fonts/sukhumvittadmai-bold.eot");
  src: local("sukhumvittadmai-bold"),
    url("../fonts/sukhumvittadmai-bold.woff") format("truetype");
  font-weight: 700;
  font-style: normal;
  size-adjust: 110%;
  font-display: swap;
  unicode-range: Ux0E00- Ux0E7F;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-Light.ttf");
  src: local("OpenSans-Light"),
    url("../fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-Regular.ttf");
  src: local("OpenSans-Regular"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-Regular.ttf");
  src: local("OpenSans-Regular"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-Semibold.ttf");
  src: local("OpenSans-Semibold"),
    url("../fonts/OpenSans-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-Bold.ttf");
  src: local("OpenSans-Bold"),
    url("../fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-ExtraBold.ttf");
  src: local("OpenSans-ExtraBold"),
    url("../fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
