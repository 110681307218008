@layer components {
  .card-bg {
    @apply w-full bg-white border border-gray-200 shadow-sm rounded;
  }

  .my-text-center {
    @apply flex justify-center items-center;
  }
  
  .my-text-right-center {
    @apply flex justify-end items-center;
  }
}